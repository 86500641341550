<template>
    <v-footer
        max-width="100vw"
        style="background-color: var(--v-customBg-base); margin-top: 12px"
    >
        <v-row justify="center" no-gutters style="width: 100%">
            <div class="my-2">
                {{ new Date().getFullYear() }} —
                <router-link to="/impressum">Impressum</router-link>
                |
                <router-link to="/datenschutz">Datenschutz</router-link>
                |
                <router-link to="/agb">AGB</router-link>
                |
                <router-link to="/widerrufsbelehrung">
                    Widerrufsbelehrung
                </router-link>
            </div>
        </v-row>
        <v-row no-gutters>
            <v-row no-gutters justify="center" align="center" class="mb-2">
                Made in
                <v-img
                    :src="require(`@/assets/deutschland.png`)"
                    max-height="48"
                    max-width="48"
                    contain
                ></v-img>
            </v-row>
            <v-col cols="12">
                <p class="font-italic text-center" style="font-size: 10px">
                    QArtCode ist eine eingetragene Marke.
                </p>
            </v-col>
            <v-col cols="12">
                <p class="font-italic text-center" style="font-size: 10px">
                    QR Code® is a registered trademark of DENSO WAVE
                    INCORPORATED
                </p>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class AppFooter extends Vue {}
</script>

<style scoped lang="scss"></style>
