import { User } from "@/interfaces/user";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import jwtDecode, { JwtPayload } from "jwt-decode";
import Config from "@/interfaces/config";
import { StudioSettings } from "@/interfaces/studio";

Vue.use(Vuex);

interface CustomStore {
    user: User | null;
    token: string | null;
    snackbar: {
        text: string;
        color: string;
        value: boolean;
        trigger: boolean;
        timeout: number;
    };
    config: Config;
    savedQArtCodes: string[];
    studioSettings: StudioSettings;
}

export default new Vuex.Store<CustomStore>({
    plugins: [
        // To prevent reset state of vuex store on page refresh
        createPersistedState({
            storage: localStorage,
        }),
    ],
    state: {
        user: null,
        token: null,
        snackbar: {
            text: "",
            color: "",
            value: false,
            trigger: false,
            timeout: 5000,
        },
        config: { autheliaUrl: "", logoutUrl: "", paypalClientId: "" },
        savedQArtCodes: [],
        studioSettings: {
            GpuEndpoint: "",
            PublishEndpoint: "",
            APIKey: "",
            currentId: "",
            debugActive: false,
        },
    },
    mutations: {
        setUser(state, payload: { user: User | null }) {
            state.user = payload.user;
        },
        setToken(state, payload: string) {
            state.token = payload;
        },
        showSnackbar(state, payload: { text: string; color: string }) {
            state.snackbar = {
                ...payload,
                value: true,
                trigger: true,
                timeout: 5000,
            };
        },
        setConfig(state, payload: Config) {
            state.config = payload;
        },
        hideSnackbar(state) {
            state.snackbar = { ...state.snackbar, value: false };
        },
        setSnackbarValue(state, payload: { value: boolean }) {
            state.snackbar = { ...state.snackbar, value: payload.value };
        },
        logout(state) {
            state.token = null;
            state.user = null;
            // window.location.href = `${state.config.autheliaUrl}/logout?targetURL=${state.config.logoutUrl}`;
        },
        addSavedQArtCode(state, payload: { qArtCode: string }) {
            state.savedQArtCodes.push(payload.qArtCode);
        },
        removeSavedQArtCode(state, payload: { qArtCode: string }) {
            state.savedQArtCodes = state.savedQArtCodes.filter(
                qArtCode => qArtCode !== payload.qArtCode
            );
        },
        setStudioSettings(state, payload: StudioSettings) {
            state.studioSettings = payload;
        },
    },
    actions: {},
    modules: {},
    getters: {
        isAuthenticated: state => {
            if (!state.token) return false;
            const decoded: JwtPayload = jwtDecode(state.token);
            if ((decoded.exp || 0) < Math.floor(new Date().getTime() / 1000))
                return false;
            return true;
        },
    },
});
