<template>
    <v-card elevation="0" class="mb-4 pt-2" color="customBg">
        <v-app-bar elevation="0" color="customBg">
            <v-row no-gutters align="center">
                <v-icon
                    x-large
                    color="primary"
                    @click="$router.push('/')"
                    style="cursor: pointer"
                >
                    mdi-qrcode
                </v-icon>
                <h2
                    class="my-0 pl-2"
                    @click="$router.push('/')"
                    style="cursor: pointer"
                >
                    QArtCode®
                </h2>
                <v-spacer></v-spacer>
                <v-btn color="accent" icon @click="changeTheme">
                    <v-icon v-if="$vuetify.theme.dark">
                        mdi-white-balance-sunny
                    </v-icon>
                    <v-icon v-if="!$vuetify.theme.dark">
                        mdi-weather-night
                    </v-icon>
                </v-btn>
                <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
            </v-row>
        </v-app-bar>
        <v-navigation-drawer
            v-model="drawer"
            temporary
            app
            right
            color="customBg"
        >
            <v-list nav dense>
                <v-list-item-group
                    v-model="group"
                    active-class="primary--text text--primary-4"
                >
                    <v-list-item to="/">
                        <v-list-item-icon>
                            <v-icon>mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Home</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        to="/studio"
                        v-if="
                            $store.getters.isAuthenticated &&
                            $store.state.user.is_studio_user
                        "
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-qrcode-edit</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Studio</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/gallery">
                        <v-list-item-icon>
                            <v-icon>mdi-grain</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Katalog</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        to="/my-gallery"
                        v-if="$store.getters.isAuthenticated"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-qrcode-scan</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>My QArtCodes</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        to="/login"
                        v-if="!$store.getters.isAuthenticated"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Login</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        to="/profile"
                        v-if="$store.getters.isAuthenticated"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Profile</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </v-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class MobileHeader extends Vue {
    drawer: boolean = false;
    group = null;

    public changeTheme() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
}
</script>

<style scoped lang="scss"></style>
