import axios from "axios";
import request from "axios";
import { CreateOrderData } from "@paypal/paypal-js";
export default class PaypalService {
    public static async createOrder(
        data: CreateOrderData,
        hashId: string
    ): Promise<string> {
        const uri = `/api/webhooks/paypal?hashId=${hashId}`;

        try {
            const response = await axios.post(uri, data);
            return response.data;
        } catch (error: unknown) {
            if (request.isAxiosError(error) && error.response) {
                return error.response.data;
            } else {
                throw Error("Unknown Error");
            }
        }
    }
    public static async releaseLock(
        orderId: string,
        hashId: string
    ): Promise<string> {
        const uri = `/api/webhooks/paypal-release-lock`;

        try {
            const response = await axios.post(
                uri,
                {},
                { params: { orderId: orderId, hashId: hashId } }
            );
            return response.data;
        } catch (error: unknown) {
            if (request.isAxiosError(error) && error.response) {
                return error.response.data;
            } else {
                throw Error("Unknown Error");
            }
        }
    }
    public static async getInvoices(token: string): Promise<string[]> {
        const uri = `/api/lexoffice/my-invoices`;

        try {
            const response = await axios.get(uri, {
                headers: { Authorization: `Bearer ${token}` },
            });
            return response.data;
        } catch (error: unknown) {
            if (request.isAxiosError(error) && error.response) {
                return error.response.data;
            } else {
                throw Error("Unknown Error");
            }
        }
    }
}
