import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

import de from "vuetify/src/locale/de";
import en from "vuetify/src/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        dark: false,
        themes: {
            light: {
                customBg: "#faece1",
                accentBg: "#efd7bf",
                primary: "#ff6900",
                accent: "#444444",
            },
            dark: {
                customBg: "#1e1e1e",
                accentBg: "#333333",
                primary: "#ff4081",
                accent: "#ff4081",
            },
        },
    },
    lang: {
        locales: {
            de,
            en,
        },
    },
});
