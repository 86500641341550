import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "../store";

Vue.use(VueRouter);

type RouteConfigSitemap = RouteConfig & {
    sitemap: boolean;
};
const routes: Array<RouteConfigSitemap> = [
    {
        path: "/",
        sitemap: true,
        name: "Home",
        component: () =>
            import(/* webpackChunkName: "register" */ "../views/Home.vue"),
        meta: { title: "QArtCode", requiresAuth: false },
    },
    {
        path: "/buy/:hashId",
        sitemap: false,
        name: "buy",
        props: true,
        component: () =>
            import(/* webpackChunkName: "buy" */ "../views/Purchase.vue"),
        meta: { title: "QArtCode", requiresAuth: false },
    },
    {
        path: "/purchase-complete",
        sitemap: false,
        name: "PurchaseComplete",
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "PurchaseComplete" */ "../views/PurchaseComplete.vue"
            ),
        meta: { title: "QArtCode", requiresAuth: false },
    },
    {
        path: "/gallery",
        sitemap: true,
        name: "Gallery",
        component: () =>
            import(/* webpackChunkName: "gallery" */ "../views/Gallery.vue"),
        meta: { title: "QArtCode", requiresAuth: false },
    },
    {
        path: "/my-gallery",
        sitemap: false,
        name: "My-Gallery",
        component: () =>
            import(
                /* webpackChunkName: "my-gallery" */ "../views/MyGallery.vue"
            ),
        meta: { title: "QArtCode", requiresAuth: false },
    },
    {
        path: "/studio",
        sitemap: false,
        name: "Studio",
        component: () =>
            import(/* webpackChunkName: "studio" */ "../views/Studio.vue"),
        meta: { title: "QArtCode", requiresAuth: true },
    },
    {
        path: "/magic-link",
        sitemap: false,
        name: "Magic-Link",
        props: route => ({
            token: route.query.token,
        }),
        component: () =>
            import(
                /* webpackChunkName: "magicLink" */ "../views/MagicLink.vue"
            ),
        meta: { title: "QArtCode", requiresAuth: false },
    },
    {
        path: "/login",
        sitemap: true,
        name: "Login",
        component: () =>
            import(
                /* webpackChunkName: "Login" */ "../components/MagicLinkLogin.vue"
            ),
        meta: { title: "QArtCode", requiresAuth: false },
    },
    {
        path: "/profile",
        sitemap: true,
        name: "Profile",
        component: () =>
            import(
                /* webpackChunkName: "Profile" */ "../components/Profile.vue"
            ),
        meta: { title: "QArtCode", requiresAuth: false },
    },
    {
        path: "/impressum",
        sitemap: true,
        name: "Impressum",
        component: () =>
            import(
                /* webpackChunkName: "Impressum" */ "../views/legal/Imprint.vue"
            ),
        meta: { title: "QArtCode", requiresAuth: false },
    },
    {
        path: "/widerrufsbelehrung",
        name: "Widerruf",
        component: () =>
            import(
                /* webpackChunkName: "Widerruf" */ "../views/legal/Widerruf.vue"
            ),
        meta: { title: "QArtCode", requiresAuth: false },
        sitemap: true,
    },
    {
        path: "/datenschutz",
        sitemap: true,
        name: "Datenschutz",
        component: () =>
            import(
                /* webpackChunkName: "Datenschutz" */ "../views/legal/Datenschutz.vue"
            ),
        meta: { title: "QArtCode", requiresAuth: false },
    },
    {
        path: "/agb",
        sitemap: true,
        name: "AGB",
        component: () =>
            import(/* webpackChunkName: "AGB" */ "../views/legal/AGB.vue"),
        meta: { title: "QArtCode", requiresAuth: false },
    },
];
export { routes };

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: to => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    },
});
const DEFAULT_TITLE = "QArtCode";
router.afterEach(to => {
    Vue.nextTick(() => {
        document.title = to.meta?.title || DEFAULT_TITLE;
    });
});

router.beforeEach(async (to, from, next) => {
    // routeguard
    const isUserLoggedIn = store.getters.isAuthenticated;
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // if token is expired and route protected, push back to login

        if (!isUserLoggedIn) {
            // User is not logged in but trying to open page which requires
            next({
                path: "/login",
            });
            store.commit("showSnackbar", {
                text: "Session abgelaufen",
                color: "error",
            });
        } else {
            // User is logged in and trying to open page which requires auth
            next();
        }
    } else if (to.name === "Login" && isUserLoggedIn) {
        // User logged in but still trying to open login page
        next({ name: "Home" });
    } else {
        // User is trying to open page which doesn't require auth
        next();
    }
});
export default router;
