<template>
    <v-container style="max-width: 1024px">
        <v-card
            :style="{
                background: $vuetify.theme.currentTheme.customBg,
            }"
            elevation="0"
        >
            <v-row no-gutters justify="end" class="">
                <v-btn outlined class="px-2" color="primary" @click="logout">
                    Logout
                    <v-icon>mdi-logout</v-icon>
                </v-btn>
            </v-row>
            <p class="my-4 text-center text-h4 accent--text">Rechnungen</p>
            <v-row no-gutters justify="center" class="my-2">
                <v-col
                    cols="12"
                    v-for="(invoice, index) in invoices"
                    :key="index"
                    class="my-2"
                >
                    <v-card outlined color="customBg">
                        <v-card-title>
                            <span class="text-h6">Rechnung</span>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn
                                        color="accent"
                                        :to="invoice"
                                        target="_blank"
                                        outlined
                                    >
                                        Download
                                        <v-icon class="ml-2">
                                            mdi-file-download-outline
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-actions>
                <v-row no-gutters justify="end" class="mx-2"></v-row>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script lang="ts">
import { User } from "@/interfaces/user";
import PaypalService from "@/services/paypalService";
import { UserService } from "@/services/userService";
import { Component, Emit, Vue } from "vue-property-decorator";

@Component
export default class Profile extends Vue {
    invoices: string[] = [];

    public async mounted(): Promise<void> {
        this.invoices = await PaypalService.getInvoices(
            this.$store.state.token
        );
    }

    public get user(): User | null {
        return this.$store.state?.user;
    }

    public logout(): void {
        this.$store.commit("logout");
        this.$router.push("/");
        this.close();
    }

    public async updateUser(): Promise<User | undefined> {
        if (!this.user) return;

        const updated = await UserService.updateMe(
            this.user,
            this.$store.state.token
        );
        if (!updated.id) {
            this.$store.commit("showSnackbar", {
                color: "error",
                text: updated,
            });
            return;
        }
        this.$store.commit("showSnackbar", {
            color: "success",
            text: "User Profil aktualisiert",
        });
        return updated;
    }

    @Emit()
    public close(): boolean {
        return true;
    }
}
</script>

<style scoped lang="scss"></style>
