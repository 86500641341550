<template>
    <v-card
        elevation="0"
        :style="{ background: $vuetify.theme.currentTheme.customBg }"
    >
        <v-row style="height: 100px" justify="center" no-gutters>
            <v-col
                class="mt-3 d-flex flex-column justify-center align-start"
                lg="4"
            >
                <v-row no-gutters justify="center" align="center">
                    <v-icon
                        style="cursor: pointer"
                        @click="$router.push('/')"
                        x-large
                        color="primary"
                    >
                        mdi-qrcode
                    </v-icon>
                    <h2
                        style="cursor: pointer"
                        @click="$router.push('/')"
                        class="my-0 pl-2"
                    >
                        QArtCode®
                    </h2>
                </v-row>
            </v-col>
            <v-col class="mt-3 d-flex flex-column align-end" lg="6" md="8">
                <v-row justify="center" align="center">
                    <v-btn
                        large
                        to="/studio"
                        color="accent"
                        text
                        v-if="
                            $store.getters.isAuthenticated &&
                            $store.state.user.is_studio_user
                        "
                    >
                        <v-icon class="mr-1">mdi-qrcode-edit</v-icon>
                        Studio
                    </v-btn>
                    <v-btn large to="/gallery" color="accent" text>
                        <v-icon class="mr-1">mdi-grain</v-icon>
                        Katalog
                    </v-btn>
                    <v-btn
                        v-if="$store.getters.isAuthenticated"
                        large
                        text
                        to="/my-gallery"
                        color="accent"
                    >
                        <v-icon class="mr-1">mdi-qrcode-scan</v-icon>
                        My QArtCodes
                    </v-btn>
                    <v-btn
                        to="/login"
                        v-if="!$store.getters.isAuthenticated"
                        large
                        text
                        color="accent"
                    >
                        <v-icon>mdi-account</v-icon>
                        Login
                    </v-btn>
                    <v-btn
                        to="/profile"
                        v-if="$store.getters.isAuthenticated"
                        large
                        text
                        color="accent"
                    >
                        <v-icon class="mr-1">mdi-account</v-icon>
                        Profile
                    </v-btn>
                    <v-btn
                        color="accent"
                        icon
                        @click="changeTheme"
                        class="mx-2"
                    >
                        <v-icon v-if="$vuetify.theme.dark">
                            mdi-white-balance-sunny
                        </v-icon>
                        <v-icon v-if="!$vuetify.theme.dark">
                            mdi-weather-night
                        </v-icon>
                    </v-btn>
                </v-row>
            </v-col>
        </v-row>
        <v-dialog
            v-model="profileDialogActive"
            persistent
            min-width="15vw"
            max-width="750px"
            :fullscreen="$vuetify.breakpoint.smAndDown"
        >
            <v-card
                :style="{ background: $vuetify.theme.currentTheme.customBg }"
            >
                <v-card-actions>
                    <v-row no-gutters justify="end">
                        <v-btn
                            color="error"
                            icon
                            @click="profileDialogActive = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-row>
                </v-card-actions>
                <v-divider></v-divider>
                <Profile @close="profileDialogActive = false"></Profile>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Profile from "@/components/Profile.vue";

@Component({ components: { Profile } })
export default class AppHeader extends Vue {
    public profileDialogActive: boolean = false;
    public changeTheme() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
}
</script>

<style scoped lang="scss"></style>
