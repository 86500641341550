<template>
    <v-app
        :style="{ background: $vuetify.theme.currentTheme.customBg }"
        style="margin-bottom: -8px"
    >
        <v-main>
            <v-alert prominent dense type="info" :value="updateExists">
                <v-row align="center" justify="space-between">
                    <v-col>Update Verfügbar!</v-col>
                    <v-btn text width="200" @click="refreshApp">
                        <v-icon>mdi-refresh</v-icon>
                        Aktualisieren
                    </v-btn>
                </v-row>
            </v-alert>
            <app-header
                v-if="$vuetify.breakpoint.mdAndUp"
                class="mx-4"
            ></app-header>
            <mobile-header v-else></mobile-header>
            <router-view class="pb-8"></router-view>
            <app-footer></app-footer>
        </v-main>
        <snackbar
            :value="snackbar.value"
            :trigger.sync="snackbar.trigger"
            @input="onSnackbarInput"
            :color="snackbar.color"
            :timeout="snackbar.timeout"
            bottom
            right
            app
        >
            {{ snackbar.text }}
            <v-btn x-small icon @click="hideSnackbar" class="justify-self-end">
                <v-icon color="black">mdi-close-circle</v-icon>
            </v-btn>
        </snackbar>
    </v-app>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import Snackbar from "@/components/Snackbar.vue";
import AppHeader from "@/components/Header.vue";
import MobileHeader from "@/components/MobileHeader.vue";
import AppFooter from "@/components/Footer.vue";
import { AuthService } from "./services/authService";

@Component({
    components: {
        Snackbar,
        AppHeader,
        MobileHeader,
        AppFooter,
    },
})
export default class App extends Vue {
    public get snackbar(): {
        text: string;
        color: string;
        value: string;
        trigger: boolean;
        timeout: number;
    } {
        return this.$store.state.snackbar;
    }

    public onSnackbarInput(value: boolean): void {
        this.$store.commit("setSnackbarValue", { value });
    }

    public hideSnackbar(): void {
        this.$store.commit("hideSnackbar");
    }

    // pwa stuff

    // refresh variables
    refreshing: boolean = false;
    registration: ServiceWorkerRegistration | null = null;
    updateExists: boolean = false;

    public created(): void {
        // Listen for our custom event from the SW registration
        document.addEventListener(
            "swUpdated",
            (event: Event) => {
                this.updateAvailable(event as CustomEvent);
            },
            {
                once: true,
            }
        );

        // Prevent multiple refreshes
        navigator.serviceWorker.addEventListener("controllerchange", () => {
            if (this.refreshing) return;
            this.refreshing = true;
            // Here the actual reload of the page occurs
            window.location.reload();
        });
    }

    public async mounted(): Promise<void> {
        try {
            const conf = await AuthService.getConfig();
            this.$store.commit("setConfig", conf);
        } catch (error) {
            console.error(error);
        }
    }
    @Watch("$vuetify.theme.dark", { immediate: true })
    public cursorSwitch(newVal: boolean): void {
        if (newVal) {
            document.documentElement.style.setProperty(
                "--cursor-url",
                'url("/qrcode_cursor_dark.svg") 12 12, auto'
            );
        } else {
            document.documentElement.style.setProperty(
                "--cursor-url",
                'url("/qrcode_cursor_light.svg") 12 12, auto'
            );
        }
    }

    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    public updateAvailable(event: { detail: ServiceWorkerRegistration }): void {
        this.registration = event.detail;
        this.updateExists = true;
    }

    // Called when the user accepts the update
    public refreshApp(): void {
        this.updateExists = false;
        // Make sure we only send a 'skip waiting' message if the SW is waiting
        if (!this.registration || !this.registration.waiting) return;
        // send message to SW to skip the waiting and activate the new SW
        this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
}
</script>

<style lang="scss">
// @font-face {
//     font-family: "Poppins";
//     src: url("assets/Poppins-Regular.ttf") format("truetype");
// }

body,
.v-application {
    font-family: "Poppins", sans-serif !important;
}
::-webkit-scrollbar-track {
    border-radius: 15px;
    background-color: var(--v-customBg-base);
}

::-webkit-scrollbar {
    width: 0.5vw;
    background-color: var(--v-customBg-base);
}

::-webkit-scrollbar-thumb {
    background-color: var(--v-primary-base);
    border-radius: 15px;
}

:root {
    scrollbar-width: thin !important;
    scrollbar-color: var(--v-primary-base) var(--v-customBg-base);
}
:root,
button,
span,
a,
div,
input,
h2,
textarea {
    cursor: var(--cursor-url) !important;
}
// * {
//     outline: 1px solid #f00 !important;
// }
</style>
